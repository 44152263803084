import React from "react";
import {
  DensityContainer,
  FormTitle,
  Comment,
} from "../components/calculator/style";
import TableForm from "../components/calculator/tableform/TableForm";
import update from "immutability-helper";
import Overlay from "../components/calculator/overlay/Overlay";
import axios from "axios";

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: "",
      alcohol_desired: 0,
      liters_desired: 0,
      type_beer: "lager",
      equipment_efficiency: 0,
      maltaData: [
        [
          {
            type: "input",
            name: "Nombre de malta",
            value: "",
            readOnly: false,
          },
          {
            type: "number",
            name: "Color",
            value: 0,
            readOnly: false,
          },
          {
            type: "number",
            name: "% de receta",
            value: 0,
            readOnly: false,
          },
          {
            type: "number",
            name: "% Extracto",
            value: 0,
            readOnly: false,
          },
          {
            type: "input",
            name: "Kg malta a pesar",
            value: 0,
            readOnly: true,
          },
          {
            type: "deleteButton",
          },
        ],
      ],
      maceracionData: [
        [
          {
            type: "number",
            name: "TEMP.(°C)",
            value: 0,
            readOnly: false,
          },
          {
            type: "number",
            name: "REPOSO (MINUTOS)",
            value: 0,
            readOnly: false,
          },
          {
            type: "deleteButton",
          },
        ],
      ],
      lupuloData: [
        [
          {
            type: "number",
            name: "Minuto",
            value: 0,
            readOnly: false,
          },
          {
            type: "input",
            name: "ingresa nombre del lupulo",
            value: "",
            readOnly: false,
          },
          {
            type: "number",
            name: "% AA",
            value: 0,
            readOnly: false,
          },
          {
            type: "number",
            name: "IBU DESEADO",
            value: 0,
            readOnly: false,
          },
          {
            type: "input",
            name: "GRAMOS A PESAR",
            value: 0,
            readOnly: true,
          },
          {
            type: "deleteButton",
          },
        ],
      ],
      levaduraData: [
        [
          {
            type: "input",
            name: "Nombre de levadura",
            value: "",
            readOnly: false,
          },
          {
            type: "number",
            name: "GR",
            value: 0,
            readOnly: false,
          },
        ],
      ],
      maltaTotalColor: 0,
      maltaTotalReceta: 0,
      maltaTotalKg: 0,
      waterSize: 0,
      lupuloTotalIBU: 0,
      maximum_usage_rate: "agreyes",
      slideshows: [],
    };

    this.waterTotal = React.createRef();
  }

  componentDidMount() {
    this.setState({
      waterSize: this.waterTotal.current.offsetWidth,
    });
  }

  handleChange = (event) => {
    let ele = event.target;
    switch (ele.type) {
      case "number":
        if (ele.value >= 0 || ele.value === "") {
          switch (ele.name) {
            case "equipment_efficiency":
              if (ele.value <= 100) {
                this.setState({ [ele.name]: ele.value }, () => {
                  this.updateMalta();
                  this.updateMaximumUse();
                });
              }
              break;
            case "liters_desired": {
              this.setState({ [ele.name]: ele.value }, () => {
                this.updateMalta();
                this.updateMaximumUse();
                this.updateLevaduraGr(this.state.type_beer);
              });
              break;
            }
            default:
              this.setState({ [ele.name]: ele.value });
              break;
          }
        }
        break;
      case "select-one":
        switch (ele.name) {
          case "maximum_usage_rate": {
            this.setState({ [ele.name]: ele.value }, () => {
              this.updateMaximumUse();
            });
            break;
          }
          case "type_beer": {
            this.setState({ [ele.name]: ele.value }, () => {
              this.updateLevaduraGr(ele.value);
            });
            break;
          }
          default:
            this.setState({ [ele.name]: ele.value });
            break;
        }
        break;
      default:
        this.setState({ [ele.name]: ele.value });
        break;
    }
  };

  validEmpty = (event) => {
    if (event.target.value === "") {
      this.setState({ [event.target.name]: 0 });
    }
  };

  getBrix = (fixed = true) => {
    const brix = this.state.alcohol_desired / 0.525 + 2.5;
    return this.getFixed(brix, fixed, 2);
  };

  getPlato = (fixed = true) => {
    const plato = this.getBrix() * 1.04;
    return this.getFixed(plato, fixed, 2);
  };

  getSg = (fixed = true) => {
    const plate = this.getPlato();
    const sg = 1 + plate / (258.6 - (plate / 258.2) * 227.1);
    return this.getFixed(sg, fixed, 3);
  };

  getSumRows = (state, index, fixed = true, round = false, fixedPos = 3) => {
    let sumTotal =
      state.reduce(
        (total, element) => total + parseFloat(element[index].value),
        0
      ) || 0;

    return this.getFixed(sumTotal, fixed, fixedPos, round);
  };

  maltaTableConfig = () => {
    return {
      size: ["19%", "19%", "19%", "19%", "19%", "5%"],
      header: [
        {
          title: "Nombre de malta",
        },
        {
          title: "Color °L",
        },
        {
          title: "% de receta",
        },
        {
          title: "% extracto",
        },
        {
          title: "Kg de malta a pesar",
        },
        {
          title: "",
        },
      ],
      content: this.state.maltaData,
      extra: [
        {
          type: "label",
          value: "Totales:",
        },
        {
          type: "",
        },
        {
          type: "input",
          name: "Total % de receta",
          value: this.state.maltaTotalReceta,
          readOnly: true,
        },
        {
          type: "",
        },
        {
          type: "input",
          name: "Total Kg malta a pesar",
          value: this.state.maltaTotalKg,
          readOnly: true,
        },
        {
          type: "",
        },
      ],
      footer: [
        {
          type: "addButton",
        },
        {
          type: "",
        },
        {
          type: "",
        },
        {
          type: "",
        },
        {
          type: "",
        },
        {
          type: "",
        },
      ],
    };
  };

  maceracionTableConfig = () => {
    return {
      size: ["45%", "45%", "10%"],
      header: [
        {
          title: "TEMP.(°C)",
        },
        {
          title: "REPOSO (MINUTOS)",
        },
        {
          title: "",
        },
      ],
      content: this.state.maceracionData,
      extra: [],
      footer: [
        {
          type: "addButton",
        },
        {
          type: "",
        },
        {
          type: "",
        },
      ],
    };
  };

  lupuloTableConfig = () => {
    return {
      size: ["19%", "19%", "19%", "19%", "19%", "5%"],
      header: [
        {
          title: "Minuto",
        },
        {
          title: "ingresa nombre del lupulo",
        },
        {
          title: "% AA",
        },
        {
          title: "IBU Deseado",
        },
        {
          title: "Gramos a pesar",
        },
        {
          title: "",
        },
      ],
      content: this.state.lupuloData,
      extra: [
        {
          type: "label",
          value: "Totales:",
        },
        {
          type: "",
        },
        {
          type: "",
        },
        {
          type: "input",
          name: "Total IBU Deseado",
          value: this.state.lupuloTotalIBU,
          readOnly: true,
        },
        {
          type: "",
        },
        {
          type: "",
        },
      ],
      footer: [
        {
          type: "addButton",
        },
        {
          type: "",
        },
        {
          type: "",
        },
        {
          type: "",
        },
        {
          type: "",
        },
        {
          type: "",
        },
      ],
    };
  };

  levaduraTableConfig = () => {
    return {
      size: ["45%", "45%", "10%"],
      header: [
        {
          title: "Ingrese nombre de levadura",
        },
        {
          title: "GR",
        },
      ],
      content: this.state.levaduraData,
      extra: [],
      footer: [],
    };
  };

  getKg = (parent, fixed = true) => {
    const { maltaData, liters_desired, equipment_efficiency } = this.state;
    const row = maltaData[parent];
    const formula = row[2].value !== "" ? row[2].value : 0;
    const extract = row[3].value !== "" ? row[3].value : 0;
    let kg =
      ((formula / 100) *
        (liters_desired * this.getSg(false) * (this.getPlato(false) / 100))) /
        ((equipment_efficiency / 100) * (extract / 100)) || 0;

    kg = Number.isFinite(kg) ? kg : 0;

    return this.getFixed(kg, fixed, 3);
  };

  getGramsToWeigh = (parent, fixed = true) => {
    const { lupuloData, liters_desired } = this.state;
    const row = lupuloData[parent];
    const formule = this.getMaximumUseFormula(row[0].value);
    let grams =
      (liters_desired * row[3].value) /
      ((formule / 100) * (row[2].value / 100) * 1000);
    grams = grams ? grams : 0;
    return this.getFixed(grams, fixed, 1);
  };

  setDataMalta = (value, parent, key) => {
    const state = update(this.state, {
      maltaData: {
        [parent]: {
          [key]: { value: { $set: value } },
        },
      },
    });
    this.setState(state, () => {
      const { maltaData } = this.state;
      const state = update(this.state, {
        maltaData: {
          [parent]: {
            4: { value: { $set: this.getKg(parent) } },
          },
        },
        maltaTotalColor: { $set: this.getSumRows(maltaData, 1, false, true) },
        maltaTotalReceta: { $set: this.getSumRows(maltaData, 2, false, true) },
      });
      this.setState(state, () => {
        const { maltaData } = this.state;
        const state = update(this.state, {
          maltaTotalKg: { $set: this.getSumRows(maltaData, 4) },
        });
        this.setState(state);
      });
    });
  };

  setDataMaceracion = (value, parent, key) => {
    const state = update(this.state, {
      maceracionData: {
        [parent]: {
          [key]: { value: { $set: value } },
        },
      },
    });
    this.setState(state);
  };

  setDataLupulo = (value, parent, key) => {
    const state = update(this.state, {
      lupuloData: {
        [parent]: {
          [key]: { value: { $set: value } },
        },
      },
    });
    this.setState(state, () => {
      const { lupuloData } = this.state;
      const state = update(this.state, {
        lupuloData: {
          [parent]: {
            4: { value: { $set: this.getGramsToWeigh(parent) } },
          },
        },
        lupuloTotalIBU: {
          $set: this.getSumRows(lupuloData, 3, true, false, 2),
        },
      });
      this.setState(state);
    });
  };

  setDataLevadura = (value, parent, key) => {
    const state = update(this.state, {
      levaduraData: {
        [parent]: {
          [key]: { value: { $set: value } },
        },
      },
    });
    this.setState(state);
  };

  addRowMalta = () => {
    const state = update(this.state, {
      maltaData: {
        $push: [
          [
            {
              type: "input",
              name: "Nombre de malta",
              value: "",
              readOnly: false,
            },
            {
              type: "number",
              name: "Color",
              value: 0,
              readOnly: false,
            },
            {
              type: "number",
              name: "% de receta",
              value: 0,
              readOnly: false,
            },
            {
              type: "number",
              name: "% Extracto",
              value: 0,
              readOnly: false,
            },
            {
              type: "input",
              name: "Kg malta a pesar",
              value: 0,
              readOnly: true,
            },
            {
              type: "deleteButton",
            },
          ],
        ],
      },
    });
    this.setState(state);
  };

  addRowMaceracion = () => {
    const state = update(this.state, {
      maceracionData: {
        $push: [
          [
            {
              type: "number",
              name: "TEMP.(°C)",
              value: 0,
              readOnly: false,
            },
            {
              type: "number",
              name: "REPOSO (MINUTOS)",
              value: 0,
              readOnly: false,
            },
            {
              type: "deleteButton",
            },
          ],
        ],
      },
    });
    this.setState(state);
  };

  addRowLupulo = () => {
    const state = update(this.state, {
      lupuloData: {
        $push: [
          [
            {
              type: "number",
              name: "Minuto",
              value: 0,
              readOnly: false,
            },
            {
              type: "input",
              name: "ingresa nombre del lupulo",
              value: "",
              readOnly: false,
            },
            {
              type: "number",
              name: "% AA",
              value: 0,
              readOnly: false,
            },
            {
              type: "number",
              name: "IBU DESEADO",
              value: 0,
              readOnly: false,
            },
            {
              type: "input",
              name: "GRAMOS A PESAR",
              value: 0,
              readOnly: true,
            },
            {
              type: "deleteButton",
            },
          ],
        ],
      },
    });
    this.setState(state);
  };

  deleteRowMalta = (parent) => {
    const state = update(this.state, {
      maltaData: { $unset: [parent] },
    });
    this.setState(state, () => {
      const { maltaData } = this.state;
      const state = update(this.state, {
        maltaTotalColor: { $set: this.getSumRows(maltaData, 1, false, true) },
        maltaTotalReceta: { $set: this.getSumRows(maltaData, 2, false, true) },
        maltaTotalKg: { $set: this.getSumRows(maltaData, 4) },
      });
      this.setState(state);
    });
  };

  deleteRowMaceracion = (parent) => {
    const state = update(this.state, {
      maceracionData: { $unset: [parent] },
    });
    this.setState(state);
  };

  deleteRowLupulo = (parent) => {
    const state = update(this.state, {
      lupuloData: { $unset: [parent] },
    });
    this.setState(state, () => {
      const { lupuloData } = this.state;
      const state = update(this.state, {
        lupuloTotalIBU: { $set: this.getSumRows(lupuloData, 3, false, false) },
      });
      this.setState(state);
    });
  };

  getErrorsGeneral = () => {
    const { alcohol_desired, liters_desired, equipment_efficiency } =
      this.state;
    let show = false;
    let message = [];
    if (this.getZeroOrEmpty(alcohol_desired)) {
      show = true;
      message.push(
        "Debe ingresar un valor diferente a vacio y mayor a 0 para Alcohol deseado."
      );
    }

    if (this.getZeroOrEmpty(liters_desired)) {
      show = true;
      message.push(
        "Debe ingresar un valor diferente a vacio y mayor a 0 para Litros deseados."
      );
    }

    if (this.getZeroOrEmpty(equipment_efficiency)) {
      show = true;
      message.push(
        "Debe ingresar un valor diferente a vacio y mayor a 0 para % Eficiencia del equipo."
      );
    }

    return {
      show: show,
      message: message,
    };
  };

  getErrorsMalta = () => {
    const { maltaTotalReceta } = this.state;

    let show = false;
    let message = [];
    if (maltaTotalReceta !== 100) {
      show = true;
      message.push("El % total de receta debe ser del 100%.");
    }

    return {
      show: !this.getErrorsGeneral().show ? show : false,
      message: message,
    };
  };

  getZeroOrEmpty = (value) => {
    if (value === "" || parseFloat(value) <= 0) {
      return true;
    }
    return false;
  };

  getTotalWater = (fixed = true) => {
    const { liters_desired, maltaTotalKg } = this.state;
    let totalWater =
      liters_desired * 1.045 * 1.05 +
      liters_desired * 1.05 * 0.08 +
      maltaTotalKg * 0.57;

    return this.getFixed(totalWater, fixed, 2);
  };

  getTogetherMalta = (fixed = true) => {
    let total = parseFloat(this.state.maltaTotalKg) * 3;
    return this.getFixed(total, fixed, 2);
  };

  getMashWater = (fixed = true) => {
    let mashWater = this.state.maltaTotalKg / 0.38;
    return this.getFixed(mashWater, fixed, 2);
  };

  getCamaLauterWater = (fixed = true) => {
    let camaLauterWater = this.getTotalWater() * 0.04;
    return this.getFixed(camaLauterWater, fixed, 2);
  };

  getPushWater = (fixed = true) => {
    let pushrWater = this.getTotalWater() * 0.025;
    return this.getFixed(pushrWater, fixed, 2);
  };

  getIrrigationWater = (fixed = true) => {
    let irrigationWater =
      this.getTotalWater(false) -
      this.getMashWater(false) -
      this.getCamaLauterWater(false) -
      this.getPushWater(false);
    return this.getFixed(irrigationWater, fixed, 2);
  };

  getSrm = (fixed = true) => {
    let srm = 0;
    this.state.maltaData.forEach((malta) => {
      srm += parseFloat(malta[1].value) * parseFloat(malta[2].value);
    });
    srm = ((srm / 100) * this.getPlato()) / 8;
    return this.getFixed(srm, fixed, 2);
  };

  getFixed = (data, fixed, decimals, round = false) => {
    if (!fixed || data === 0) {
      if (round) {
        return Math.round(data);
      }
      return data;
    }
    return data.toFixed(decimals);
  };

  getMaximumUseFormula = (data) => {
    switch (this.state.maximum_usage_rate) {
      case "noonan":
        return -0.0021 * (data * data) + 0.5253 * data + 2.1099;
      case "rager":
        return -0.0041 * (data * data) + 0.7127 * data + 0.3839;
      case "daniels":
        return -0.0025 * (data * data) + 0.4658 * data + 5.4256;
      case "tinseth":
        return Math.log(data) * 7.4785 - 7.9853;
      case "garetz":
        return -0.0041 * (data * data) + 0.683 * data - 5.654;
      case "mosher":
        return -0.0018 * (data * data) + 0.3513 * data + 3.0277;
      default:
        return Math.log(data) * 6.5104 - 3.2586;
    }
  };

  updateMaximumUse = (key = 0) => {
    const { lupuloData } = this.state;
    const state = update(this.state, {
      lupuloData: {
        [key]: {
          4: { value: { $set: this.getGramsToWeigh(key) } },
        },
      },
    });
    this.setState(state, () => {
      if (key + 1 < lupuloData.length) {
        this.updateMaximumUse(key + 1);
      }
    });
  };

  updateLevaduraGr = (data) => {
    const { liters_desired } = this.state;
    let levaduraGr = 0;
    switch (data) {
      case "lager":
        levaduraGr = (liters_desired * 22) / 25;
        break;

      default:
        levaduraGr = (liters_desired * 11) / 25;
        break;
    }
    const state = update(this.state, {
      levaduraData: {
        0: {
          1: { value: { $set: levaduraGr } },
        },
      },
    });
    this.setState(state);
  };

  updateMalta = (parent = 0) => {
    const state = update(this.state.maltaData, {
      [parent]: {
        4: { value: { $set: this.getKg(parent) } },
      },
    });
    this.setState({ maltaData: state }, () => {
      let parentCount = parent + 1;
      if (Object.keys(this.state.maltaData).length > parentCount) {
        this.updateMalta(parentCount);
      } else {
        this.setState(state, () => {
          const { maltaData } = this.state;
          const state = update(this.state, {
            maltaTotalKg: { $set: this.getSumRows(maltaData, 4) },
          });
          this.setState(state);
        });
      }
    });
  };

  downloadReport = () => {
    const {
      style,
      alcohol_desired,
      lupuloTotalIBU,
      liters_desired,
      maltaData,
      maltaTotalKg,
      maceracionData,
      lupuloData,
      levaduraData,
    } = this.state;

    const maltas = maltaData.map((item) => {
      return {
        title: item[0].value,
        value: item[4].value,
      };
    });

    const maceracion = maceracionData.map((item) => {
      return {
        temp: item[0].value,
        rest: item[1].value,
      };
    });

    const lupulo = lupuloData.map((item) => {
      return {
        min: item[0].value,
        lupulo: item[1].value,
        gr: item[4].value,
        aa: item[2].value,
      };
    });

    let data = {
      title: style,
      alcohol: alcohol_desired,
      srm: this.getSrm(),
      ibu: lupuloTotalIBU,
      liters: liters_desired,
      maltas: maltas,
      maltas_total: maltaTotalKg,
      water_mash: this.getTogetherMalta(),
      water_irrigation: this.getIrrigationWater(),
      waters: maceracion,
      lupulos: lupulo,
      brix: this.getBrix(),
      plato: this.getPlato(),
      sg: this.getSg(),
      levadura: {
        name: levaduraData[0][0].value,
        value: levaduraData[0][1].value,
      },
    };

    axios({
      url: "https://api.v1.tecnologiacervecera.com/generator",
      method: "POST",
      data: data,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Reporte.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    // this.getMaltaData();
    return (
      <React.Fragment>
        <div className="content-wrap blog-single-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="content-page-wrap">
                  <h3 className="title">Calculadora</h3>
                  {/* <form id="form-reply" method="get"> */}
                  <div className="container form-calculator">
                    <div className="row">
                      <div className="col-sm-12 col-md-3 title bold">
                        Estilo
                      </div>
                      <div className="col-sm-12 col-md-3 input">
                        <input
                          type="text"
                          name="style"
                          placeholder="Estilo"
                          value={this.state.style}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-3 title bold">
                        Alcohol deseado
                      </div>
                      <div className="col-sm-12 col-md-3 input">
                        <input
                          type="number"
                          name="alcohol_desired"
                          placeholder="Alcohol deseado"
                          value={this.state.alcohol_desired}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          onBlur={(e) => {
                            this.validEmpty(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-3 title bold">
                        Litros deseados
                      </div>
                      <div className="col-sm-12 col-md-3 input">
                        <input
                          type="number"
                          name="liters_desired"
                          placeholder="Litros deseados"
                          value={this.state.liters_desired}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          onBlur={(e) => {
                            this.validEmpty(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-3 title bold">Tipo</div>
                      <div className="col-sm-12 col-md-3 input">
                        <select
                          name="type_beer"
                          value={this.state.type_beer}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        >
                          <option value="lager">Lager</option>
                          <option value="ale">Ale</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-3 title bold">
                        % Eficiencia del equipo
                      </div>
                      <div className="col-sm-12 col-md-3 input">
                        <input
                          type="number"
                          name="equipment_efficiency"
                          placeholder="% Eficiencia del equipo"
                          value={this.state.equipment_efficiency}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="right">%</span>
                      </div>
                    </div>
                  </div>
                  <Overlay {...this.getErrorsGeneral()}>
                    <FormTitle>Densidad despues de hervir</FormTitle>
                    <DensityContainer>
                      <div className="row">
                        <div className="col-sm-12 col-md-4">
                          <input
                            type="text"
                            placeholder="° Brix"
                            value={this.getBrix()}
                            readOnly
                          />
                          <span>° Brix</span>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input
                            type="text"
                            placeholder="° Plato"
                            value={this.getPlato()}
                            readOnly
                          />
                          <span>° Plato</span>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input
                            type="text"
                            placeholder="SG"
                            value={this.getSg()}
                            readOnly
                          />
                          <span>SG</span>
                        </div>
                      </div>
                    </DensityContainer>
                    <FormTitle>Malta</FormTitle>
                    <TableForm
                      config={this.maltaTableConfig()}
                      setData={this.setDataMalta}
                      addRow={this.addRowMalta}
                      deleteRow={this.deleteRowMalta}
                    />
                    <Overlay {...this.getErrorsMalta()}>
                      <div className=" container form-calculator">
                        <div className="row">
                          <div className="col-sm-6 col-md-3 title bold">
                            L AGUA TOTAL
                          </div>
                          <div className="col-sm-6 col-md-3 input">
                            <input
                              type="text"
                              placeholder="L AGUA TOTAL"
                              value={this.getTotalWater()}
                              ref={this.waterTotal}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-3 title bold">
                            L AGUA MASH
                          </div>
                          <div className="col-sm-12 col-md-3 input">
                            <input
                              type="text"
                              name="style"
                              placeholder="L AGUA MASH"
                              value={this.getMashWater()}
                              style={{
                                width: this.state.waterSize,
                              }}
                              readOnly
                            />
                          </div>
                          <div className="col-sm-12 col-md-3 title bold">
                            SI TIENES EL MASH Y LAUTER JUNTOS:
                          </div>
                          <div className="col-sm-12 col-md-3 input">
                            <input
                              type="text"
                              placeholder="Litros"
                              value={this.getTogetherMalta()}
                              style={{
                                width: this.state.waterSize,
                              }}
                              readOnly
                            />
                            <span className="right" style={{ width: "100px" }}>
                              LITROS
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-md-3 title bold">
                            L AGUA CAMA LAUTER
                          </div>
                          <div className="col-sm-6 col-md-3 input">
                            <input
                              type="text"
                              placeholder="L AGUA CAMA LAUTER"
                              value={this.getCamaLauterWater()}
                              style={{
                                width: this.state.waterSize,
                              }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-md-3 title bold">
                            L AGUA PARA EMPUJE
                          </div>
                          <div className="col-sm-6 col-md-3 input">
                            <input
                              type="text"
                              placeholder="L AGUA PARA EMPUJE"
                              value={this.getPushWater()}
                              style={{
                                width: this.state.waterSize,
                              }}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-md-3 title bold">
                            L AGUA PARA RIEGO
                          </div>
                          <div className="col-sm-6 col-md-3 input">
                            <input
                              type="text"
                              name="style"
                              placeholder="Estilo"
                              value={this.getIrrigationWater()}
                              style={{
                                width: this.state.waterSize,
                              }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="conatiner">
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <FormTitle>Maceración</FormTitle>
                            <TableForm
                              config={this.maceracionTableConfig()}
                              setData={this.setDataMaceracion}
                              addRow={this.addRowMaceracion}
                              deleteRow={this.deleteRowMaceracion}
                            />
                          </div>
                        </div>
                      </div>
                      <FormTitle>Color</FormTitle>
                      <div className="container form-calculator">
                        <div className="row">
                          <div className="col-sm-12 col-md-3 title bold">
                            SRM
                          </div>
                          <div className="col-sm-12 col-md-3 input">
                            <input
                              type="text"
                              name="srm"
                              placeholder="SRM"
                              value={this.getSrm()}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      {/* <FormContainer>
                        <FormLabel>SRM</FormLabel>
                        <FormInput>
                          <input
                            type="text"
                            name="style"
                            placeholder="SRM"
                            value={this.getSrm()}
                            readOnly
                          />
                        </FormInput>
                      </FormContainer> */}
                      <Comment>
                        NOTA: SI DESEA CAMBIAR EL COLOR ES NECESARIO MODIFICAR
                        LOS % DE SU RECETA
                      </Comment>
                      <FormTitle>Lúpulo</FormTitle>
                      <div className="container form-calculator">
                        <div className="row">
                          <div className="col-sm-12 col-md-3 title bold">
                            % de utilzación máximo
                          </div>
                          <div className="col-sm-12 col-md-3 input">
                            <select
                              name="maximum_usage_rate"
                              value={this.state.maximum_usage_rate}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            >
                              <option value="noonan">NOONAN 31%</option>
                              <option value="rager">RAGER 30%</option>
                              <option value="daniels">DANIELS 27%</option>
                              <option value="agreyes">AGREYES 25%</option>
                              <option value="tinseth">TINSETH 24.7%</option>
                              {/* <option value="garetz">GARETZ 23%</option> */}
                              <option value="mosher">MOSHER 20.8%</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <FormContainer>
                        <FormLabel>% de utilzación máximo</FormLabel>
                        <FormSelect>
                          <select
                            name="maximum_usage_rate"
                            value={this.state.maximum_usage_rate}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="noonan">NOONAN 31%</option>
                            <option value="rager">RAGER 30%</option>
                            <option value="daniels">DANIELS 27%</option>
                            <option value="agreyes">AGREYES 25%</option>
                            <option value="tinseth">TINSETH 24.7%</option>
                            <option value="mosher">MOSHER 20.8%</option>
                          </select>
                        </FormSelect>
                      </FormContainer> */}
                      <TableForm
                        config={this.lupuloTableConfig()}
                        setData={this.setDataLupulo}
                        addRow={this.addRowLupulo}
                        deleteRow={this.deleteRowLupulo}
                      />
                      <div className="conatiner">
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <FormTitle>Levadura</FormTitle>
                            <TableForm
                              config={this.levaduraTableConfig()}
                              setData={this.setDataLevadura}
                              addRow={() => {}}
                              deleteRow={() => {}}
                            />
                          </div>
                        </div>
                      </div>
                      <Comment className="blue">
                        NOTA: SE RECOMIENDA HACER CASO AL PROVEEDOR DE LEVADURA
                        RESPECTO AL PESO
                      </Comment>
                    </Overlay>
                    <div className="wrap-btn">
                      <button
                        type="button"
                        className="flat-button btn-send"
                        onClick={() => this.downloadReport()}
                      >
                        Generar Documento
                      </button>
                    </div>
                  </Overlay>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Calculator;
