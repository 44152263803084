import styled from "styled-components";

export const OverlayContainer = styled.div`
  position: relative;
`;

export const OverlayInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
`;

export const OverlayMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px;
  background: lightgray;
  margin: 30px auto;
  span {
    padding: 0 10px;
    width: 100%;
    font-size: 16px;

    &.header {
      font-weight: bold;
    }
  }
`;
