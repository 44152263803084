import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const TableContainerHeader = styled.div`
  display: flex;

  div {
    background-color: #cb824d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 55px;
    margin: 0 2px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 550px) {
    div {
      font-size: 10px;
    }
  }
`;

export const TableContainerContent = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      height: 55px;
      background-color: white;
      color: black;
    }

    .delete-btn {
      font-size: 18px;
      cursor: pointer;
    }

    margin: 0 2px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  // @media only screen and (max-width: 550px) {
  //   div {
  //     button {
  //       font-size: 8px !important;
  //       padding: 0 5px;
  //     }
  //   }
  // }
`;
