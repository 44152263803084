import React from "react";
import {
  TableContainer,
  TableContainerHeader,
  TableContainerContent,
} from "./style";

class TableForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //   componentDidMount() {
  //     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   }

  getTableType = (element, parent, key) => {
    const { setData, addRow, deleteRow } = this.props;
    // console.log(element.type);
    switch (element.type) {
      case "input":
        return (
          <input
            type="text"
            placeholder={element.name}
            value={element.value}
            readOnly={element.readOnly}
            onChange={(e) => {
              setData(e.target.value, parent, key);
            }}
          />
        );
      case "number":
        return (
          <input
            type="number"
            placeholder={element.name}
            value={element.value}
            readOnly={element.readOnly}
            onChange={(e) => {
              setData(e.target.value, parent, key);
            }}
          />
        );
      case "addButton": {
        return (
          <button
            type="button"
            className="flat-button btn-send"
            onClick={addRow}
          >
            Agregar
          </button>
        );
      }
      case "deleteButton": {
        return (
          <span
            className="fa fa-trash delete-btn"
            onClick={() => deleteRow(parent)}
          ></span>
        );
      }

      case "label": {
        return <span>{element.value}</span>;
      }

      default:
        return "";
    }
  };

  render() {
    const { config } = this.props;
    return (
      <TableContainer>
        <TableContainerHeader>
          {config.header.map((element, key) => {
            return (
              <div style={{ width: config.size[key] }} key={key}>
                {element.title}
              </div>
            );
          })}
        </TableContainerHeader>
        {config.content.map((row, parentKey) => {
          return (
            <TableContainerContent key={parentKey}>
              {row.map((element, key) => {
                return (
                  <div style={{ width: config.size[key] }} key={key}>
                    {this.getTableType(element, parentKey, key)}
                  </div>
                );
              })}
            </TableContainerContent>
          );
        })}
        <TableContainerContent>
          {config.extra.map((row, key) => {
            return (
              <div style={{ width: config.size[key] }} key={key}>
                {this.getTableType(row, key, key)}
              </div>
            );
          })}
        </TableContainerContent>
        <TableContainerContent>
          {config.footer.map((row, key) => {
            return <div key={key}>{this.getTableType(row, key, key)}</div>;
          })}
        </TableContainerContent>
      </TableContainer>
    );
  }
}
export default TableForm;
