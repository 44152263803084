import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.css";
import "./styles/responsive.css";
import "./styles/shortcode.css";
import "./styles/liblary.css";
import "./styles/custom.css";
import Calculator from "./sections/Calculator";

function App() {
  return (
    <Calculator />
  );
}

export default App;
