import styled from "styled-components";

export const FormTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const FormContainer = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: 20px;

  div {
    flex: 2;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  input,
  select {
    margin-left: 10px;
    color: #000000;
    background-color: #ffffff;
  }
`;

export const FormContainerRtl = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: 20px;

  &.full {
    width: 100%;
  }

  div {
    flex: 2;
    display: flex;
    align-items: center;

    &.separate {
      margin-left: 10px;
    }
  }

  input,
  select {
    color: #000000;
    background-color: #ffffff;
  }
`;

export const FormLabel = styled.div`
  font-size: 15px;
  background-color: #cb824d;
  color: white;
  // padding-right: 15px;
  // padding-left: 15px;
  // position: relative;
  // width: 100%;
`;

export const FormInput = styled.div``;

export const FormInputNumber = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
`;

export const FormSelect = styled.div``;

export const DensityContainer = styled.div`
  .row {
    margin-bottom: 20px;
    div {
      display: flex;

      input {
        flex: 1;
        height: 55px;
      }
      span {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #cb824d;
        margin-left: 15px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        border-radius: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    .row {
      div {
        span {
          background-color: #ffffff;
          color: #cb824d;
        }
      }
    }
  }
  // display: flex;
  // margin-bottom: 20px;

  // div {
  //   display: flex;
  //   flex: auto;
  //   align-items: center;

  //   input {
  //     flex: 2;
  //     color: #000000;
  //   }

  //   span {
  //     flex: 1;
  //     margin-left: 10px;
  //   }
  // }
`;

export const WaterContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  div {
    display: flex;
    flex: auto;
    align-items: center;

    input {
      flex: 2;
      color: #000000;
    }

    span {
      flex: 1;
      margin-left: 10px;

      &.bold {
        font-weight: bold;
      }
    }
  }
`;

export const HalfContainer = styled.div`
  width: 50%;
`;

export const Comment = styled.div`
  color: red;
  margin-bottom: 20px;

  &.blue {
    color: lightblue;
  }
`;
