import React from "react";
import { OverlayContainer, OverlayInfo, OverlayMessage } from "./style";

class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { show, message } = this.props;
    return (
      <OverlayContainer>
        {show && (
          <OverlayInfo>
            <OverlayMessage>
              <span className="header">Para continuar:</span>
              {message.map((msg, key) => (
                <span key={key}>{msg}</span>
              ))}
            </OverlayMessage>
          </OverlayInfo>
        )}
        {this.props.children}
      </OverlayContainer>
    );
  }
}
export default Overlay;
